import { useEffect, useRef, useState } from "react";
import ComponentView from "./component/ComponentView";
import MeetingJoinForm from "./MeetingJoinForm";
import { addVirtualStream, removeVirtualStream } from "./common/media-devices";

function generateSignatureMeetingJoinOptions(options) {
  const signature = window.generateSignature({
    apiKey: process.env.REACT_APP_API_KEY,
    apiSecret: process.env.REACT_APP_API_SECRET,
    meetingNumber: options.meeting_number,
    role: options.meeting_role
  });
  return {
    ...options,
    apiKey: process.env.REACT_APP_API_KEY,
    signature
  };
}

function pad(no) {
  return no < 10 ? `0${no}` : `${no}`;
}

function displayCurrentTimer(canvas, ctx, startTime) {
  ctx.fillStyle = "#374151";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = "white";

  ctx.font = "202px Arial";

  let displayTime = "";

  const timer = (new Date().getTime() - startTime) / 1000;

  const minutes = pad(Math.floor(timer / 60));
  const seconds = pad(Math.floor(timer % 60));
  displayTime = `${minutes}:${seconds}`;
  ctx.fillText(displayTime, 187, 375);
}

function leaveMeeting(setJoin) {
  var leave = document.querySelector(
    "div[id=darukZoomWrapper] button[title=Leave]"
  );
  leave.click();

  setTimeout(() => {
    var spans = document.querySelectorAll("button > span");

    var endMeeting;
    for (let index = 0; index < spans.length; index++) {
      const span = spans[index];
      if (span.textContent === "End Meeting for All") {
        endMeeting = span.parentElement;
      }
    }
    endMeeting.click();
    setJoin(false);
  }, 1000);
}

function setupVideo(canvasRef, videoRef) {
  const canvasStream = canvasRef.current.captureStream(30);
  addVirtualStream(canvasStream);
}

function App() {
  const mountPoint = useRef();

  const canvasRef = useRef();
  const videoRef = useRef();
  const [meetingOptions, setMeetingOptions] = useState();
  const [join, setJoin] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d", { alpha: false });

    const startTime = new Date().getTime();
    const intervalId = setInterval(() => {
      displayCurrentTimer(canvas, context, startTime);
    }, 1000);
    return () => {
      removeVirtualStream();
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="container-md">
      <h1 className="text-center my-2">Zoom Meeting POC</h1>
      <MeetingJoinForm
        onJoin={value => {
          setupVideo(canvasRef);
          setMeetingOptions(generateSignatureMeetingJoinOptions(value));
          setJoin(true);
        }}
        onLeave={() => leaveMeeting(setJoin)}
        onSetupVideo={() => setupVideo(canvasRef, videoRef)}
        join={join}
      />
      <canvas
        ref={canvasRef}
        width={960}
        height={540}
        className="card"
      ></canvas>
      <video ref={videoRef} autoPlay />
      <div id="darukZoomWrapper" style={{ display: "none" }}><div ref={mountPoint}></div></div>

      {join ? (
        <ComponentView
          mountPoint={mountPoint}
          meetingOptions={meetingOptions}
          onDisconnect={() => setJoin(false)}
        />
      ) : null}
    </div>
  );
}

export default App;
