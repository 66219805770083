import React from "react";
import { useState } from "react";

function MeetingJoinForm({ onJoin, onLeave, onSetupVideo }) {
  const [formData, setFormData] = useState({
    meeting_number: "7169162531",
    meeting_pwd: "v0aBKt",
    display_name: "Zoom POC",
    meeting_email: "info@skematix.in",
    meeting_role: 1
  });

  const setValue = (key, value) =>
    setFormData(prevState => {
      return { ...prevState, [key]: value };
    });

  return (
    <div className="container-sm mt-2 border p-4">
      <form className="row g-3">
        <div className="col-md-6">
          <label htmlFor="meeting_number" className="form-label">
            Meeting Number
          </label>
          <input
            type="text"
            className="form-control"
            id="meeting_number"
            value={formData.meeting_number}
            onChange={e => setValue("meeting_number", e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="meeting_pwd" className="form-label">
            Meeting Password
          </label>
          <input
            type="text"
            className="form-control"
            id="meeting_pwd"
            value={formData.meeting_pwd}
            onChange={e => setValue("meeting_pwd", e.target.value)}
          />
        </div>
        <div className="col-12">
          <label htmlFor="display_name" className="form-label">
            Display Name
          </label>
          <input
            type="text"
            className="form-control"
            id="display_name"
            placeholder="Display Name"
            value={formData.display_name}
            onChange={e => setValue("display_name", e.target.value)}
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="meeting_email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="meeting_email"
            placeholder="Email"
            value={formData.meeting_email}
            onChange={e => setValue("meeting_email", e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="inputState" className="form-label">
            Role
          </label>
          <select
            id="meeting_role"
            className="form-select"
            value={formData.meeting_role}
            onChange={e => setValue("meeting_role", e.target.value)}
          >
            <option value="0">Attendee</option>
            <option value="1">Host</option>
            <option value="5">Assistant</option>
          </select>
        </div>
        <div className="col-12 text-center">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={e => {
              e.preventDefault();
              onJoin(formData);
            }}
          >
            Join
          </button>
          <button
            type="submit"
            className="btn btn-danger mx-2"
            onClick={e => {
              e.preventDefault();
              onLeave();
            }}
          >
            Leave Meeting
          </button>
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={e => {
              e.preventDefault();
              onSetupVideo();
            }}
          >
            Setup Video
          </button>
        </div>
      </form>
    </div>
  );
}

export default MeetingJoinForm;
