/* eslint-disable no-undef */
let enumerateDevicesFn;
let getUserMediaFn;

function convertI420AFrameToI420Frame(frame) {
  const { width, height } = frame.codedRect;
  const buffer = new Uint8Array(width * height * 3);
  frame.copyTo(buffer, { rect: frame.codedRect });
  const init = {
    timestamp: 0,
    codedWidth: width,
    codedHeight: height,
    format: "I420"
  };
  // eslint-disable-next-line no-undef
  return new VideoFrame(buffer, init);
}

function transform(stream) {
  const videoTrack = stream.getVideoTracks()[0];

  const trackProcessor = new MediaStreamTrackProcessor({
    track: videoTrack
  });
  const trackGenerator = new MediaStreamTrackGenerator({ kind: "video" });

  const transformer = new TransformStream({
    async transform(videoFrame, controller) {
      const newFrame = convertI420AFrameToI420Frame(videoFrame);
      videoFrame.close();
      controller.enqueue(newFrame);
    }
  });

  trackProcessor.readable
    .pipeThrough(transformer)
    .pipeTo(trackGenerator.writable);

  const processedStream = new MediaStream();
  processedStream.addTrack(trackGenerator);
  return processedStream;
}


function addVirtualStream(stream) {
  enumerateDevicesFn = MediaDevices.prototype.enumerateDevices;
  getUserMediaFn = MediaDevices.prototype.getUserMedia;

  const virtualStream = transform(stream);
  MediaDevices.prototype.enumerateDevices = async function () {
    const res = await enumerateDevicesFn.call(navigator.mediaDevices);
    // We could add "Virtual VHS" or "Virtual Median Filter" and map devices with filters.
    res.push({
      deviceId: "virtual",
      groupID: "uh",
      kind: "videoinput",
      label: "Virtual Stream"
    });
    return res;
  };

  MediaDevices.prototype.getUserMedia = async function () {
    const args = arguments;
    console.log(args[0]);
    if (args.length && args[0].video && !args[0].audio) {
      return virtualStream;
    }
    const res = await getUserMediaFn.call(navigator.mediaDevices, ...arguments);
    console.log(res);
    return res;
  };

  console.log("Added Virtual Stream");
}

function removeVirtualStream() {
  if (enumerateDevicesFn) {
    MediaDevices.prototype.enumerateDevices = enumerateDevicesFn;
  }
  if (getUserMediaFn) {
    MediaDevices.prototype.getUserMedia = getUserMediaFn;
  }
  console.log("Removed Virtual Stream");
}

export { addVirtualStream, removeVirtualStream };
