import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import React, { useEffect, useMemo, useState } from "react";

function getConfig(config) {
  return {
    apiKey: config.apiKey,
    meetingNumber: config.meeting_number,
    userName: config.display_name,
    password: config.meeting_pwd,
    leaveUrl: "/index.html",
    role: 1,
    userEmail: config.meeting_email,
    lang: "en-US",
    signature: config.signature || "",
    china: false,
    webEndpoint: "zoom.us"
  };
}

function connect(client, config, setStatus, mountElement) {
  const rootElement = mountElement; //document.getElementById("ZoomEmbeddedApp");
  const tmpPort = window.location.port === "" ? "" : ":" + window.location.port;
  const avLibUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    tmpPort +
    "/lib";
  client
    .init({
      debug: true,
      zoomAppRoot: rootElement,
      assetPath: avLibUrl,
      language: config.lang
    })
    .then(() => {
      return client.join({
        apiKey: config.apiKey,
        signature: config.signature,
        meetingNumber: config.meetingNumber,
        userName: config.userName,
        password: config.password,
        userEmail: config.userEmail
      });
    })
    .then(e => {
      setStatus({
        loading: false,
        error: false,
        message: ""
      });
      console.log("join success", e);
    })
    .catch(e => {
      setStatus({
        loading: false,
        error: true,
        message: e.message
      });
    });
}

function useZoomMeeting(config, mountElement) {
  const client = useMemo(() => ZoomMtgEmbedded.createClient(), []);
  const [status, setStatus] = useState({
    loading: true,
    error: false,
    message: ""
  });

  useEffect(
    () => connect(client, config, setStatus, mountElement),
    // () => console.log(JSON.stringify(config)),
    [client, config, mountElement]
  );

  useEffect(() => {
    if (status.loading || status.error) {
      return;
    }
    const startVideo = mountElement.querySelector("button[title='Start Video']");
    startVideo.click();
  }, [status, mountElement])

  return {
    status,
    client
  };
}

function ComponentView({ mountPoint, meetingOptions, onDisconnect }) {
  const { status, client } = useZoomMeeting(
    getConfig(meetingOptions),
    mountPoint.current
  );
  if (status.loading) {
    return <div>Loading...</div>;
  }

  if (status.error) {
    return <div>Error: {status.message}</div>;
  }

  const meetingInfo = client.getCurrentMeetingInfo();

  return (
    <div className="container-md">
      <h1>
        Joined {meetingInfo.meetingTopic} with meeting id
        {meetingInfo.meetingNumber}
      </h1>
      <h3>Participants</h3>
      <ul>
        {client.getAttendeeslist().map(participant => {
          return <li key={participant.userId}>{participant.displayName}</li>;
        })}
      </ul>
    </div>
  );
}

export default ComponentView;
